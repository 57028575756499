import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Button from "@mui/material/Button";
import { THEME, DASHBOARD, SA_MANAGEMENT } from "../../constant";
import { useSelector, useDispatch } from "react-redux";

export default function DashboardMainViewContent() {
    let isLaptopOrMobile = useMediaQuery({
        minWidth: 850,
    });
    return (
        <>
            <div className="w-[100%] h-auto relative z-50">
                {isLaptopOrMobile ? (
                    <></>
                ) : (
                    <>
                        <div className="bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[240px] bg-Logo-01"></div>
                    </>
                )}
                <div className="ml-[10%] mt-5 w-[80%]">
                    <h1 className=" text-[50px] font-normal leading-[70px] font-sans">Monetize your Inbox:</h1>
                    <h1 className="mt-[30px] text-[50px] font-normal leading-[40px] font-sans">Get Paid For</h1>
                    <h1 className="mt-[30px] text-[40px] font-normal leading-[40px] font-sans">Your Spam & Promotion Emails</h1>
                    <span className="h-[20px] block" />
                    <a className="mt-[30px] text-[20px] font-normal leading-[30px] font-sans">Transform your mail inbox into a source of income with our innovative extension. Use Tollbooth to get paid for promotion and spam emails. If an email sender wants to reach you, make them pay you!
                        Tollbooth intercepts spam and promotion emails and offers the senders the opportunity to pay you in order for you to see those emails in your primary inbox.</a>

                    {isLaptopOrMobile ? (
                        <>
                            <div className="flex mt-[100px] mb-[50px]">
                                <div>
                                    <div className="flex justify-between  w-[200px] h-auto pl-[5px] items-center rounded border-white border-solid border-[1px]">
                                        <a>Get Started</a>
                                        <div className="bg-[white] rounded border-white border-solid border-[1px] h-[30px] w-[30px] ">
                                            <div className="bg-no-repeat bg-contain bg-right_arrow w-[20px] h-[20px] m-auto mt-[5px]"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ml-[30px]">
                                    <div className="flex justify-between  w-[200px] h-auto pl-[5px] items-center rounded border-white border-solid border-[1px] bg-[#005aff]">
                                        <a href="https://chromewebstore.google.com/detail/tollbooth/ipepgfpplofhkcjbccbhggajjgncjoba" target="_blank" className="text-[white]">Download Extension</a>
                                        <div className="bg-[white] rounded-sm h-[30px] w-[30px] ">
                                            <div className="bg-no-repeat bg-contain bg-download_icon w-[30px] h-[30px] m-auto "></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mt-[100px] mb-[50px]">
                                <div>
                                    <div className="flex justify-between  w-[200px] h-auto pl-[5px] items-center rounded border-white border-solid border-[1px]">
                                        <a>Get Started</a>
                                        <div className="bg-[white] rounded border-white border-solid border-[1px] h-[30px] w-[30px] ">
                                            <div className="bg-no-repeat bg-contain bg-right_arrow w-[20px] h-[20px] m-auto mt-[5px]"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-[30px]">
                                    <div className="flex justify-between  w-[200px] h-auto pl-[5px] items-center rounded border-white border-solid border-[1px] bg-[#005aff]">
                                        <a href="https://chromewebstore.google.com/detail/tollbooth/ipepgfpplofhkcjbccbhggajjgncjoba" target="_blank" className="text-[white]">Download Extension</a>
                                        <div className="bg-[white] rounded-sm h-[30px] w-[30px] ">
                                            <div className="bg-no-repeat bg-contain bg-download_icon w-[30px] h-[30px] m-auto "></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}


                </div>
            </div>
        </>
    );
}
